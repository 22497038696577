import { saasModernTheme } from 'common/theme/saasModern';
import styled from 'styled-components';

export const Title = styled.h2`
  
  font-size: ${(props) => props.size || `${saasModernTheme.space[9]}px`};
  font-weight: ${(props) => props.fontWeight || 700};
  color: ${(props) => props.color || 'white'};
  @media (max-width: 450px) {
    font-size: ${(props) =>
      props.responsiveSise || `${saasModernTheme.space[8]}px`};
  }
`;
